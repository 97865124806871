import { Injectable } from '@angular/core';
import PCancelable from 'p-cancelable';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ESortOrder } from '../../../../model/ESortOrder';
import { PerformanceManager } from '../../../performance/PerformanceManager';
import { ICountAllResponse } from '../../../sqlite/models/icount-all-response';
import { IInsertRequest } from '../../../sqlite/models/iinsert-request';
import { SqlDataSource } from '../../../sqlite/models/sql-data-source';
import { SqlRequestResult } from '../../../sqlite/models/sql-request-result';
import { TRequestParam } from '../../../sqlite/models/trequest-param';
import { TTransactionRequest } from '../../../sqlite/models/ttransaction-request';
import { LocalDatabaseProviderService } from '../../../sqlite/services/providers/local-database-provider.service';
import { SqlRequestService } from '../../../sqlite/services/sql-request.service';
import { SqlService } from '../../../sqlite/services/sql.service';
import { AND_REQUEST } from '../../../sqlite/sql.constants';
import { ETrackingStatus } from '../models/etracking-status.enum';
import { IChangeTrackerItem } from '../models/ichange-tracker-item';
import { ILot } from '../models/ilot';
import { ChangeTrackingService } from './change-tracking.service';

@Injectable()
export class MobileChangeTrackingService extends ChangeTrackingService {

	//#region FIELDS

	private static readonly C_DEFAULT_DATABASE_VERSION = 1;
	private static readonly C_CHANGE_TRACKER_ITEMS_KEYS: (keyof IChangeTrackerItem)[] = ["id", "rev", "lotId"];
	private static readonly C_LOT_ITEMS_KEYS: (keyof ILot)[] = ["id", "since"];
	private static readonly C_LOG_ID = "MOBILECHANGETRACK.S::";

	private readonly moTrackerDataSourceSubjectsByDatabaseId = new Map<string, ReplaySubject<SqlDataSource>>();

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcSql: SqlService,
		private readonly isvcSqlRequest: SqlRequestService,
		private readonly isvcAndroidProvider: LocalDatabaseProviderService
	) {
		super();
	}

	public override async trackMultipleAsync(
		psDatabaseId: string,
		paChangeTrackerItems: IChangeTrackerItem[]
	): Promise<void> {
		try {
			console.debug(`${MobileChangeTrackingService.C_LOG_ID}Tracking documents for '${psDatabaseId}'.`, paChangeTrackerItems);

			const loPerformanceManager = new PerformanceManager().markStart();
			const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);
			const lsOnConflitRequest: string = this.isvcSqlRequest.getOnConflictUpdateRequest<IChangeTrackerItem>(
				["id", "lotId"],
				["rev"]
			);
			const laInsertRequests: IInsertRequest[] = this.isvcSqlRequest.getInsertRequest<IChangeTrackerItem>(
				MobileChangeTrackingService.C_CHANGES_TABLE_NAME,
				MobileChangeTrackingService.C_CHANGE_TRACKER_ITEMS_KEYS,
				paChangeTrackerItems
			);
			const loLastLot: ILot | undefined = await this.getLastLotWithDataSourceAsync(loDataSource, psDatabaseId);
			const laRequests: string[] = [];
			const laBatchedValues: TRequestParam[][] = [];
			let lnNextBatchStart = 0;

			laInsertRequests.forEach((poInsertRequest: IInsertRequest) => { // Pour chaque requête d'insert, on vient préparer les valeurs.
				laRequests.push(`${poInsertRequest.request} ${lsOnConflitRequest}`);
				laBatchedValues.push(
					this.prepareChangeTrackerItems(
						paChangeTrackerItems.slice(lnNextBatchStart, poInsertRequest.nbRows),
						loLastLot?.id
					)
				);
				lnNextBatchStart += poInsertRequest.nbRows + 1;
			});
			await this.isvcSql.runTransactionAsync(
				loDataSource,
				laRequests,
				laBatchedValues,
				loDataSource.databaseId
			);
			await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.tracked);

			console.debug(`${MobileChangeTrackingService.C_LOG_ID}Documents tracked for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);
		} catch (poError) {
			console.error(`${MobileChangeTrackingService.C_LOG_ID}Error while tracking document for '${psDatabaseId}'.`, poError);
			await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.error);
			throw poError;
		}
	}

	private prepareChangeTrackerItems(paChangeTrackerItems: IChangeTrackerItem[], pnLotId?: number): TRequestParam[] {
		const lnLotId: number = pnLotId ?? MobileChangeTrackingService.C_START_LOT_ID;
		return paChangeTrackerItems.map((poChangeTrackerItem: IChangeTrackerItem) => ([
			poChangeTrackerItem.id,
			poChangeTrackerItem.rev,
			lnLotId
		])).flat();
	}

	public override getTrackedAsync(psDatabaseId: string, poToLot?: ILot): PCancelable<IChangeTrackerItem[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new PCancelable<IChangeTrackerItem[]>(async (pfResolve, pfReject) => {
			try {
				const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);
				const lsRequest: string = this.getTrackedRequest();
				const loResult: SqlRequestResult<IChangeTrackerItem> = await this.isvcSql.requestAsync<IChangeTrackerItem>(
					loDataSource,
					lsRequest,
					[poToLot?.id ?? MobileChangeTrackingService.C_START_LOT_ID],
					loDataSource.databaseId
				);
				console.debug(`${MobileChangeTrackingService.C_LOG_ID}Get tracked for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(loResult.results);
			} catch (poError) {
				pfReject(poError);
			}
		});
	}

	private getTrackedRequest(): string {
		return `${this.isvcSqlRequest.selectAllFromTableRequest(MobileChangeTrackingService.C_CHANGES_TABLE_NAME)} \
WHERE lotId <= ?`;
	}

	public override dropTrackedAsync(psDatabaseId: string, pnLotId: number, paDocIds: string[]): PCancelable<void> {
		return new PCancelable(async (pfResolve, pfReject) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			try {
				const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);
				const laRequests: TTransactionRequest[] = [this.getDeleteTrackedRequest(paDocIds)];

				if (pnLotId > 1) // Le lot 0 n'existe pas et on n'a potentiellement pas terminé de synchronisé le lot 1
					laRequests.push(this.getDeleteLotsRequest());

				await this.isvcSql.runTransactionAsync(
					loDataSource,
					laRequests,
					[
						[...paDocIds, pnLotId],
						[pnLotId - 1] // On ne veux pas supprimer le lot en cours de synchro
					],
					loDataSource.databaseId
				);

				await this.sendTrackingStatusAsync(
					psDatabaseId,
					await this.hasTrackedAsync(psDatabaseId) ? ETrackingStatus.tracked : ETrackingStatus.none
				);
				console.debug(`${MobileChangeTrackingService.C_LOG_ID}Drop tracked for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve();
			}
			catch (poError) {
				await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.error);
				pfReject(poError);
			}
		});
	}

	private getDeleteLotsRequest(): string {
		return `${this.isvcSqlRequest.getDeleteRequestLessOrEqual<ILot>(MobileChangeTrackingService.C_LOTS_TABLE_NAME, "id")}`;
	}

	private getDeleteTrackedRequest(paDocIds: string[]): string {
		return `${this.isvcSqlRequest.getDeleteRequestByIds(MobileChangeTrackingService.C_CHANGES_TABLE_NAME, paDocIds)} \
${AND_REQUEST} lotId <= ?`;
	}

	public override getAndUpdateLastLotAsync(psDatabaseId: string, pnSince: number): PCancelable<ILot[]> {
		return new PCancelable(async (pfResolve, pfReject) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			try {
				const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);
				const laRequests: TTransactionRequest[] = [
					this.isvcSqlRequest.selectAllFromTableRequest(MobileChangeTrackingService.C_LOTS_TABLE_NAME),
					([poLotsResult]: [SqlRequestResult<ILot>]) => ArrayHelper.getFirstElement(this.isvcSqlRequest.getInsertRequest(
						MobileChangeTrackingService.C_LOTS_TABLE_NAME,
						MobileChangeTrackingService.C_LOT_ITEMS_KEYS,
						[{
							id: (ArrayHelper.getLastElement(poLotsResult.results)?.id ?? MobileChangeTrackingService.C_START_LOT_ID) + 1,
							since: pnSince
						}]
					))?.request
				];

				const laResults: SqlRequestResult<any>[] = await this.isvcSql.runTransactionAsync(
					loDataSource,
					laRequests,
					[
						undefined,
						([poLotsResult]: [SqlRequestResult<ILot>]) => ([
							(ArrayHelper.getLastElement(poLotsResult.results)?.id ?? MobileChangeTrackingService.C_START_LOT_ID) + 1,
							pnSince
						])
					],
					loDataSource.databaseId
				);
				console.debug(`${MobileChangeTrackingService.C_LOG_ID}Get and update last lot for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(ArrayHelper.getFirstElement(laResults)?.results ?? []);
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	private async getLastLotWithDataSourceAsync(poDataSource: SqlDataSource, psDatabaseId: string): Promise<ILot | undefined> {
		const loPerformanceManager = new PerformanceManager().markStart();

		const lsRequest: string = this.getLastLotRequest();

		const loResult: SqlRequestResult<ILot> =
			await this.isvcSql.requestAsync<ILot>(poDataSource, lsRequest, [], poDataSource.databaseId);
		console.debug(`${MobileChangeTrackingService.C_LOG_ID}Get last lot for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);

		return ArrayHelper.getFirstElement(loResult.results);
	}

	private getLastLotRequest(): string {
		return `${this.isvcSqlRequest.selectAllFromTableRequest(MobileChangeTrackingService.C_LOTS_TABLE_NAME)} \
${this.isvcSqlRequest.getOrderByRequest("id", ESortOrder.descending)} ${this.isvcSqlRequest.limitRequest(1)}`;
	}

	protected override async hasTrackedAsync(psDatabaseId: string): Promise<boolean> {
		const loPerformanceManager = new PerformanceManager().markStart();
		const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);
		const lsRequest: string = this.getFirstTrackedRequest();

		const loResult: SqlRequestResult<IChangeTrackerItem> =
			await this.isvcSql.requestAsync<IChangeTrackerItem>(loDataSource, lsRequest, [], loDataSource.databaseId);
		console.debug(`${MobileChangeTrackingService.C_LOG_ID}Check hasTracked for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);

		return loResult.hasResults();
	}

	private getFirstTrackedRequest(): string {
		return `${this.isvcSqlRequest.selectAllFromTableRequest(MobileChangeTrackingService.C_CHANGES_TABLE_NAME)} \
${this.isvcSqlRequest.limitRequest(1)}`;
	}

	private async getSqlDataSourceAsync(psDatabaseId: string): Promise<SqlDataSource> {
		let loDataSourceSubject: ReplaySubject<SqlDataSource> | undefined =
			this.moTrackerDataSourceSubjectsByDatabaseId.get(psDatabaseId);

		let loDataSource: SqlDataSource | undefined;
		if (!loDataSourceSubject) {
			this.moTrackerDataSourceSubjectsByDatabaseId.set(psDatabaseId, loDataSourceSubject = new ReplaySubject);
			const lsTrackerDatabaseId: string = this.getTrackerDatabaseId(psDatabaseId);
			loDataSource = await this.openDatabase(lsTrackerDatabaseId);
			await this.createTablesAsync(loDataSource, lsTrackerDatabaseId);
			loDataSourceSubject.next(loDataSource);
		}

		if (!loDataSource)
			loDataSource = await loDataSourceSubject.asObservable().pipe(take(1)).toPromise();

		return loDataSource;
	}

	public async openDatabase(
		psTrackerDatabaseId: string
	): Promise<SqlDataSource> {
		const loDataSource = new SqlDataSource(
			psTrackerDatabaseId,
			MobileChangeTrackingService.C_DEFAULT_DATABASE_VERSION,
			this.isvcAndroidProvider.getFileName(
				psTrackerDatabaseId,
				MobileChangeTrackingService.C_DEFAULT_DATABASE_VERSION
			),
			true
		);

		await this.isvcSql.openDatabaseAsync(loDataSource);
		return loDataSource;
	}

	private async createTablesAsync(poDataSource: SqlDataSource, psTrackerDatabaseId: string): Promise<void> {
		const laRequests: string[] = [
			this.isvcSqlRequest.getCreateTableRequest<IChangeTrackerItem>(
				MobileChangeTrackingService.C_CHANGES_TABLE_NAME,
				["lotId", "id"],
				[{ key: "id", value: "TEXT" }, { key: "rev", value: "TEXT" }, { key: "lotId", value: "NUMBER" }]
			),
			this.isvcSqlRequest.getCreateTableRequest<ILot>(
				MobileChangeTrackingService.C_LOTS_TABLE_NAME,
				["id"],
				[{ key: "id", value: "NUMBER" }, { key: "since", value: "NUMBER" }]
			)
		];

		await this.isvcSql.runTransactionAsync(poDataSource, laRequests, [], poDataSource.databaseId);
	}

	public override getLastLotAsync(psDatabaseId: string): PCancelable<ILot | undefined> {
		return new PCancelable(async (pfResolve, pfReject) => {
			try {
				const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);

				pfResolve(await this.getLastLotWithDataSourceAsync(loDataSource, psDatabaseId));
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	public override getTrackerDatabaseId(psDatabaseId: string): string {
		return super.getTrackerDatabaseId(psDatabaseId).replace(/_/g, "-");
	}

	public override async countTrackedAsync(psDatabaseId: string): Promise<number> {
		const loDataSource: SqlDataSource = await this.getSqlDataSourceAsync(psDatabaseId);

		return ArrayHelper.getFirstElement(
			(await this.isvcSql.requestAsync<ICountAllResponse>(
				loDataSource,
				this.isvcSqlRequest.getCountRequest(MobileChangeTrackingService.C_CHANGES_TABLE_NAME),
				[],
				loDataSource.databaseId
			)).results
		)?.count ?? 0;
	}

	//#endregion METHODS

}
