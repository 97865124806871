import { Injectable } from '@angular/core';
import PCancelable from 'p-cancelable';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { PerformanceManager } from '../../../performance/PerformanceManager';
import { tapError } from '../../../utils/rxjs/operators/tap-error';
import { ETrackingStatus } from '../models/etracking-status.enum';
import { IChangeTrackerItem } from '../models/ichange-tracker-item';
import { ILot } from '../models/ilot';
import { ChangeTrackingService } from './change-tracking.service';

@Injectable()
export class BrowserChangeTrackingService extends ChangeTrackingService {

	//#region FIELDS

	private static readonly C_CHANGES_ID_INDEX_NAME = "id";
	private static readonly C_LOG_ID = "BROWSERCHANGETRACK.S::";

	private readonly moIDBTrackerDatabaseSubjectsByDatabaseId = new Map<string, ReplaySubject<IDBDatabase>>();

	//#endregion

	//#region METHODS

	public override trackMultipleAsync(psDatabaseId: string, paChangeTrackerItems: IChangeTrackerItem[]): Promise<void> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (pfResolve, pfReject) => {
			// On exclut les _local pour ne pas les tracer pour éviter de les répliquer par la suite.
			const laChangeTrackerItems: IChangeTrackerItem[] = paChangeTrackerItems.filter((poItem: IChangeTrackerItem) => !poItem.id.startsWith(EPrefix.local));

			if (ArrayHelper.hasElements(laChangeTrackerItems)) {
				const loPerformanceManager = new PerformanceManager().markStart();

				console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Tracking documents for '${psDatabaseId}'.`, laChangeTrackerItems);
				try {
					const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
					const loTransaction: IDBTransaction = loDb.transaction(
						[
							BrowserChangeTrackingService.C_CHANGES_TABLE_NAME,
							BrowserChangeTrackingService.C_LOTS_TABLE_NAME
						],
						"readwrite"
					);

					loTransaction.oncomplete = async () => {
						console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Documents tracked for '${psDatabaseId}' \
in : ${loPerformanceManager.markEnd().measure()}ms.`);
						pfResolve();
						await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.tracked);
					};
					loTransaction.onerror = loTransaction.onabort = async (poEvent: Event) => {
						pfReject((poEvent.target as IDBTransaction).error);
						await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.error);
					};

					const loLastLot: ILot | undefined = await this.getLastLotInTransactionAsync(loTransaction, psDatabaseId);

					await this.putNextAsync(
						paChangeTrackerItems,
						0,
						loTransaction.objectStore(BrowserChangeTrackingService.C_CHANGES_TABLE_NAME),
						loLastLot?.id ?? BrowserChangeTrackingService.C_START_LOT_ID
					);
				}
				catch (poError) {
					pfReject(poError);
				}
			}
			else
				pfResolve();
		});
	}

	public override getTrackedAsync(psDatabaseId: string, poToLot?: ILot): PCancelable<IChangeTrackerItem[]> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new PCancelable<IChangeTrackerItem[]>(async (pfResolve, pfReject, pfOnCancel) => {
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([BrowserChangeTrackingService.C_CHANGES_TABLE_NAME], "readonly");

			pfOnCancel(() => loTransaction.abort());

			loTransaction.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);

			loTransaction.objectStore(BrowserChangeTrackingService.C_CHANGES_TABLE_NAME)
				.getAll(IDBKeyRange.upperBound([(poToLot?.id ?? BrowserChangeTrackingService.C_START_LOT_ID) + 1], true)) // On récupère les marqueurs du lot.
				.onsuccess = (poEvent: Event) => {
					console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Get tracked for '${psDatabaseId}' in\
: ${loPerformanceManager.markEnd().measure()}ms.`);
					pfResolve((poEvent.target as IDBRequest).result);
				};
		});
	}

	public override dropTrackedAsync(psDatabaseId: string, pnLotId: number, paDocIds: string[]): PCancelable<void> {
		const loPromise = new PCancelable<void>(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			if (ArrayHelper.hasElements(paDocIds)) {
				const loTransaction: IDBTransaction = loDb.transaction(
					[
						BrowserChangeTrackingService.C_CHANGES_TABLE_NAME,
						BrowserChangeTrackingService.C_LOTS_TABLE_NAME
					],
					"readwrite"
				);

				pfOnCancel(() => loTransaction.abort());

				loTransaction.oncomplete = async () => {
					await this.sendTrackingStatusAsync(psDatabaseId, await this.hasTrackedAsync(psDatabaseId) ? ETrackingStatus.tracked : ETrackingStatus.none);
					pfResolve();
					console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Drop tracked for '${psDatabaseId}' in \
: ${loPerformanceManager.markEnd().measure()}ms.`);
				};
				loTransaction.onerror = async (poEvent: Event) => {
					pfReject((poEvent.target as IDBTransaction).error);
					await this.sendTrackingStatusAsync(psDatabaseId, ETrackingStatus.error);
				};

				paDocIds.sort(); // On trie pour avoir l'ordre de l'index dans la base.
				loTransaction.objectStore(BrowserChangeTrackingService.C_CHANGES_TABLE_NAME)
					// On ouvre un curseur pour parcourir la base jusqu'au dernier doc supprimé.
					.openCursor(IDBKeyRange.upperBound([pnLotId, ArrayHelper.getLastElement(paDocIds)]))
					.onsuccess = (poEvent: Event) => this.processDropTrackedCursorEvent(poEvent, paDocIds, pnLotId, loTransaction);
			}
			else
				pfResolve();
		});

		return loPromise;
	}

	private processDropTrackedCursorEvent(
		poEvent: Event,
		paDocIds: string[],
		pnLot: number,
		poTransaction: IDBTransaction
	): void {
		const loCursor: IDBCursorWithValue = (poEvent.target as IDBRequest).result;
		if (loCursor) {
			if (ArrayHelper.binarySearch(paDocIds, ArrayHelper.getLastElement(loCursor.key as string[]))) // On récupère le dernier élément de la clé qui est l'id du document (ex: cont_...).
				loCursor.delete();

			loCursor.continue();
		}
		else if (pnLot > 1) { // Le lot 0 n'existe pas
			poTransaction.objectStore(BrowserChangeTrackingService.C_LOTS_TABLE_NAME)
				// On supprime les anciens lots, car on n'a potentiellement pas terminé le lot en cours
				.openCursor(IDBKeyRange.upperBound(pnLot - 1))
				.onsuccess = (poLotsEvent: Event) => {
					const loLotsCursor: IDBCursorWithValue = (poLotsEvent.target as IDBRequest).result;
					if (loLotsCursor) {
						loLotsCursor.delete();
						loLotsCursor.continue();
					}
				};
		}
	}

	public override getAndUpdateLastLotAsync(psDatabaseId: string, pnSince: number): PCancelable<ILot[]> {
		return new PCancelable(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([BrowserChangeTrackingService.C_LOTS_TABLE_NAME], "readwrite");

			pfOnCancel(() => loTransaction.abort());

			const laLots: ILot[] = await this.getLotsInTransactionAsync(
				loTransaction,
				psDatabaseId,
				(poLotsObjectStore: IDBObjectStore, paLots: ILot[]) => {
					const loAddPerformanceManager = new PerformanceManager().markStart();
					const loRequest: IDBRequest = poLotsObjectStore.add({
						id: (ArrayHelper.getLastElement(paLots)?.id ?? BrowserChangeTrackingService.C_START_LOT_ID) + 1,
						since: pnSince
					} as ILot);
					loRequest.onsuccess = () =>
						console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Update last lot for '${psDatabaseId}' in \
: ${loAddPerformanceManager.markEnd().measure()}ms.`);
				}
			);

			loTransaction.oncomplete = () => {
				console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Get and update last lot for '${psDatabaseId}' in \
: ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(laLots);
			};
			loTransaction.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	public getLotsAsync(psDatabaseId: string): PCancelable<ILot[]> {
		return new PCancelable(async (pfResolve, pfReject, pfOnCancel) => {
			try {
				const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
				const loTransaction: IDBTransaction = loDb.transaction([BrowserChangeTrackingService.C_LOTS_TABLE_NAME], "readwrite");

				pfOnCancel(() => loTransaction.abort());

				const laLots: ILot[] = await this.getLotsInTransactionAsync(
					loTransaction,
					psDatabaseId,
				);

				pfResolve(laLots);
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	/** Récupère tous les lots.
	 * @param poObjectStore
	 * @param pfCallback Utile pour exécuter un traitement en conservant la transaction.
	 */
	private getLotsInTransactionAsync(
		poTransaction: IDBTransaction,
		psDatabaseId: string,
		pfCallback?: (poObjectStore: IDBObjectStore, paLots: ILot[]) => Promise<void> | void
	): Promise<ILot[]> {
		const loPerformanceManager = new PerformanceManager().markStart();
		return new Promise((pfResolve, pfReject) => {
			const loLotsObjectStore: IDBObjectStore = poTransaction.objectStore(BrowserChangeTrackingService.C_LOTS_TABLE_NAME);
			const loRequest: IDBRequest = loLotsObjectStore.getAll();

			loRequest.onsuccess = async (poEvent: Event) => {
				console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Get lots for '${psDatabaseId}' in \
: ${loPerformanceManager.markEnd().measure()}ms.`);
				const laLots: ILot[] = (poEvent.target as IDBRequest).result;
				if (pfCallback)
					await pfCallback(loLotsObjectStore, laLots);
				pfResolve(laLots);
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	/** Récupère le dernier lot.
	 * @param poTransaction
	 */
	private getLastLotInTransactionAsync(poTransaction: IDBTransaction, psDatabaseId: string): Promise<ILot | undefined> {
		const loPerformanceManager = new PerformanceManager().markStart();

		return new Promise((pfResolve, pfReject) => {
			const loRequest: IDBRequest<IDBCursorWithValue | null> = poTransaction.objectStore(BrowserChangeTrackingService.C_LOTS_TABLE_NAME)
				.openCursor(undefined, "prev");

			loRequest.onsuccess = (poEvent: Event) => {
				const loLotsCursor: IDBCursorWithValue | undefined = (poEvent.target as IDBRequest).result;
				console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Get last lot for '${psDatabaseId}' in \
: ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(loLotsCursor?.value);
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	public override getLastLotAsync(psDatabaseId: string): PCancelable<ILot | undefined> {
		return new PCancelable(async (pfResolve, pfReject, pfOnCancel) => {
			try {
				const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
				const loTransaction: IDBTransaction = loDb.transaction([BrowserChangeTrackingService.C_LOTS_TABLE_NAME], "readonly");

				pfOnCancel(() => loTransaction.abort());
				pfResolve(await this.getLastLotInTransactionAsync(loTransaction, psDatabaseId));
			}
			catch (poError) {
				pfReject(poError);
			}
		});
	}

	/** Permet de sauvegarder les marqueurs un par un mais en restant dans la même transaction (impossible de faire des bulk put sur indexeddb). Les transactions sont coûteuses.
	 * @param paChangeTrackerItems
	 * @param pnIndex
	 * @param poObjectStore
	 * @param pnLotId
	 */
	private putNextAsync(
		paChangeTrackerItems: IChangeTrackerItem[],
		pnIndex: number,
		poObjectStore: IDBObjectStore,
		pnLotId: number
	): Promise<void> {
		return new Promise((pfResolve, pfReject) => {
			if (pnIndex < paChangeTrackerItems.length) {
				const loItem: IChangeTrackerItem = paChangeTrackerItems[pnIndex];
				const loRequest: IDBRequest = poObjectStore.add({
					id: loItem.id,
					rev: loItem.rev,
					lotId: pnLotId
				} as IChangeTrackerItem);

				++pnIndex;
				loRequest.onsuccess = () => this.putNextAsync(paChangeTrackerItems, pnIndex, poObjectStore, pnLotId).then(pfResolve).catch(pfReject);
				loRequest.onerror = (poEvent: Event) => {
					const loError: DOMException | null = (poEvent.target as IDBTransaction).error;
					if (loError?.code === 0) { // Permet de gérer le cas d'une valeur déjà présente
						poEvent.preventDefault();
						poEvent.stopPropagation();
						this.putNextAsync(paChangeTrackerItems, pnIndex, poObjectStore, pnLotId).then(pfResolve).catch(pfReject);
					}
					else
						pfReject(poEvent);
				};
			}
			else
				pfResolve();
		});
	}

	public getDbAsync(psDatabaseId: string): Promise<IDBDatabase> {
		let loSubject: ReplaySubject<IDBDatabase> | undefined = this.moIDBTrackerDatabaseSubjectsByDatabaseId.get(psDatabaseId);

		if (!loSubject) {
			loSubject = this.openDb(psDatabaseId);
			this.moIDBTrackerDatabaseSubjectsByDatabaseId.set(psDatabaseId, loSubject);
		}

		return loSubject.asObservable().pipe(
			take(1),
			tapError(() => this.moIDBTrackerDatabaseSubjectsByDatabaseId.delete(psDatabaseId)) // Si erreur on enlève le sujet du cache
		).toPromise();
	}

	public async closeDbAsync(psDatabaseId: string): Promise<void> {
		const loSubject: ReplaySubject<IDBDatabase> | undefined = this.moIDBTrackerDatabaseSubjectsByDatabaseId.get(psDatabaseId);

		if (loSubject) {
			const loDb: IDBDatabase = await loSubject.asObservable().pipe(take(1)).toPromise();
			loDb.close();
		}
	}

	private openDb(psDatabaseId: string): ReplaySubject<IDBDatabase> {
		const loSubject = new ReplaySubject<IDBDatabase>(1);
		const loOpenDbRequest: IDBOpenDBRequest = indexedDB.open(this.getTrackerDatabaseId(psDatabaseId));

		loOpenDbRequest.onerror = (poEvent: Event) => {
			console.error(`${BrowserChangeTrackingService.C_LOG_ID}Error while creating change \
tracking database for ${psDatabaseId}`, (poEvent.target as IDBOpenDBRequest).error);
			loSubject.error(loOpenDbRequest.error);
		};

		loOpenDbRequest.onsuccess = (poEvent: Event) => loSubject.next((poEvent.target as IDBOpenDBRequest).result);

		loOpenDbRequest.onupgradeneeded = (poEvent: Event) => { // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB#creating_or_updating_the_version_of_the_database
			const loDb: IDBDatabase = (poEvent.target as IDBOpenDBRequest).result;

			const loChangesObjectStore: IDBObjectStore = loDb.createObjectStore(
				BrowserChangeTrackingService.C_CHANGES_TABLE_NAME,
				{ keyPath: ["lotId", "id"] }
			);
			loChangesObjectStore.createIndex(BrowserChangeTrackingService.C_CHANGES_ID_INDEX_NAME, "id");
			loDb.createObjectStore(BrowserChangeTrackingService.C_LOTS_TABLE_NAME, { keyPath: "id" });
		};

		return loSubject;
	}

	protected override hasTrackedAsync(psDatabaseId: string): PCancelable<boolean> {
		return new PCancelable<boolean>(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([BrowserChangeTrackingService.C_CHANGES_TABLE_NAME], "readonly");

			pfOnCancel(() => loTransaction.abort());

			const loLotsObjectStore: IDBObjectStore = loTransaction.objectStore(BrowserChangeTrackingService.C_CHANGES_TABLE_NAME);

			const loRequest: IDBRequest<number> = loLotsObjectStore.count();

			loRequest.onsuccess = async (poEvent: Event) => {
				console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Check if has documents tracked for '${psDatabaseId}' in \
: ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve((poEvent.target as IDBRequest).result > 0);
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	public override async countTrackedAsync(psDatabaseId: string): Promise<number> {
		return new PCancelable<number>(async (pfResolve, pfReject, pfOnCancel) => {
			const loPerformanceManager = new PerformanceManager().markStart();
			const loDb: IDBDatabase = await this.getDbAsync(psDatabaseId);
			const loTransaction: IDBTransaction = loDb.transaction([BrowserChangeTrackingService.C_CHANGES_TABLE_NAME], "readonly");

			pfOnCancel(() => loTransaction.abort());

			const loLotsObjectStore: IDBObjectStore = loTransaction.objectStore(BrowserChangeTrackingService.C_CHANGES_TABLE_NAME);

			const loRequest: IDBRequest<number> = loLotsObjectStore.count();

			loRequest.onsuccess = async (poEvent: Event) => {
				const lnNbDocs: number = (poEvent.target as IDBRequest).result;
				console.debug(`${BrowserChangeTrackingService.C_LOG_ID}Get ${lnNbDocs} documents tracked for '${psDatabaseId}' in \
: ${loPerformanceManager.markEnd().measure()}ms.`);
				pfResolve(lnNbDocs);
			};

			loRequest.onerror = (poEvent: Event) => pfReject((poEvent.target as IDBTransaction).error);
		});
	}

	//#endregion

}
