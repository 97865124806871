import { EEnvironmentId } from '@calaosoft/osapp/model/application/EEnvironmentId';
import { IEnvironment } from '@calaosoft/osapp/model/environment/IEnvironment';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const APP_ID = "merch";
export const APP_CORE_DATABASE_PREFIX = `${APP_ID}_core_`;

export const environment: IEnvironment = {
	production: false,
	id: EEnvironmentId.beta,
	description: "TEXBETA",
	cloud_url: "https://cloudtexbeta.mobile.calaosoft.fr",
	coreRoleAppConfig: `${APP_CORE_DATABASE_PREFIX}common_app_configs`,
	statusBar: { backgroundColorByHexString: "#f43a2c" },
	thumbnailLogo: "assets/icon/merch-icon-beta.png",

	cloud_api_suffix: "/api/",
	cloud_dms_suffix: "/dms/merch/documents/",
	cloud_api_data_suffix: `/api/apps/${APP_ID}/data/`,
	cloud_api_apps_suffix: `/api/apps/${APP_ID}/`,

	dms: {},

	appCredentials: {
		login: "merchapp",
		password: "deyozota81"
	},

	unlockRequired: true,

	API_KEY: "ODUxODcyZTJlOTFhNDY3OGEyYWI0MTA4OTY4NDU2ZGE",
};

/** Url de connexion vers l'ERP Textilot. */
export const TEXTILOT_DASHBOARD_URL = "https://texpluserp-extra.textilot.fr/account/login?returnUrl=%2Fdashboard&embedded=true&token={{externalToken}}";